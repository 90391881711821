import 'intersection-observer';
import scrollama from 'scrollama';

// instantiate the scrollama
const scroller = scrollama();

// setup the instance, pass callback functions
scroller
  .setup({
    step: '.js-in-view',
    offset: 0.75, // From 0 to 1 : 0 being the top and 1 the bottom of the screen. Can be in 'px' too.
    once: true,
  })
  .onStepEnter((response) => {
    // { element, index, direction }
    response.element.classList.add('in-view');
  })
  .onStepExit((response) => {
    // { element, index, direction }
  })
  .onStepProgress((response) => {
    // { element, index, direction }
  });

// Setup resize event
window.addEventListener('resize', scroller.resize);
