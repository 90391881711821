/**
 * Webfont Loader to improve performance
 * @url https://github.com/typekit/webfontloader
 * ---
 *
 * Google Font: https://github.com/typekit/webfontloader#google
 * 400 = normal 400
 * 700i = italic 700 (bold italic)
 *
 * Adobe Typekit, créer un projet et y associer une ou plusieurs police(s)
 * Puis récupérer l'ID du projet : https://fonts.adobe.com/my_fonts#web_projects-section
 */

import WebFont from 'webfontloader'

WebFont.load({
  google: {
    families: ['Fira Sans:400,500,700']
  },
  // typekit: {
  //   id: 'bvp3asx'
  // },
});
