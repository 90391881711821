/**
 * Swiper 11.0.3
 * @url : https://swiperjs.com/api/
 */
// import Swiper JS
// core version + navigation, pagination, freemode & keyboard modules:
import Swiper from 'swiper'
import { FreeMode, Keyboard, Navigation, Pagination } from 'swiper/modules'

/**
* Default + providers + freemode
*/
// Default
const psSwiper = Array.from(document.getElementsByClassName('js-swiper-page-standard'))
const singleProviderSwiper = Array.from(document.getElementsByClassName('js-swiper-single-provider'))

if (psSwiper.length) {
  for (const swiper of psSwiper) {
    // Swiper instance
    const psSwiperInstance = new Swiper(swiper, {
      modules: [Navigation, Pagination, Keyboard],
      slidesPerView: 2,
      spaceBetween: 20,
      breakpoints: {
        767: {
          slidesPerView: 2,
          spaceBetween: 32
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 16
        }
      },
      autoplay: true,
      disableOnInteraction: true,
      loop: true,
      speed: 2000,
      navigation: {
        nextEl: swiper.querySelector('.swiper-button-next'),
        prevEl: swiper.querySelector('.swiper-button-prev'),
      },
      pagination: {
        el: swiper.querySelector('.swiper-pagination'),
        clickable: true,
      },
      keyboard: true,
      init: false,
    })

    // Init only if there is 2 or more slides
    const nbSlides = Array.from(swiper.getElementsByClassName('swiper-slide'))

    if (nbSlides.length > 1)
      psSwiperInstance.init()
  }
}

if (singleProviderSwiper.length) {
  for (const swiper of singleProviderSwiper) {
    // Swiper instance
    const psSwiperInstance = new Swiper(swiper, {
      modules: [Navigation, Pagination, Keyboard],
      slidesPerView: 1,
      spaceBetween: 20,
      autoplay: true,
      disableOnInteraction: true,
      loop: true,
      speed: 2000,
      navigation: {
        nextEl: swiper.querySelector('.swiper-button-next'),
        prevEl: swiper.querySelector('.swiper-button-prev'),
      },
      pagination: {
        el: swiper.querySelector('.swiper-pagination'),
        clickable: true,
      },
      keyboard: true,
      init: false,
    })

    // Init only if there is 2 or more slides
    const nbSlides = Array.from(swiper.getElementsByClassName('swiper-slide'))

    if (nbSlides.length > 1)
      psSwiperInstance.init()
  }
}

// Freemode
const swiperFreemode = Array.from(document.getElementsByClassName('js-swiper-freemode'))
if (swiperFreemode.length) {
  for (const swiper of swiperFreemode) {
    // Swiper instance
    const swiperFreeModeInstance = new Swiper(swiper, {
      modules: [Navigation, Pagination, FreeMode, Keyboard],
      slidesPerView: 'auto',
      centeredSlides: Foundation.MediaQuery.current !== 'small',
      grabCursor: true,
      freeMode: true,
      spaceBetween: (Foundation.MediaQuery.current === 'small') ? 16 : 32,
      speed: 700,
      navigation: {
        nextEl: swiper.querySelector('.swiper-button-next'),
        prevEl: swiper.querySelector('.swiper-button-prev'),
      },
      keyboard: true,
      init: false,
    })

    // Init only if there is 2 or more slides
    const nbSlides = Array.from(swiper.getElementsByClassName('swiper-slide'))

    if (nbSlides.length > 1)
      swiperFreeModeInstance.init()
  }
}

