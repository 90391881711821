/**
 * Gravity Form
 * Adds Material Design Floating Labels on Gravity Forms
 * @dependencies jquery https://jquery.com/
 * @dependencies autosize https://github.com/jackmoore/autosize
 */

import $ from 'jquery';
import autosize from 'autosize';
import jquery from 'jquery';

/**
 * Init post render (and reinit after ajax error / success)
 * @url https://docs.gravityforms.com/gform_post_render/
 */
$(document).on('gform_post_render', function(event, form_id, current_page){

  // Remove Loading class if exists
  document.body.classList.remove('is-loading');

  // Wrapper closure for events
  const gforms = document.querySelectorAll('.is-material-ui');

  if (gforms.length) {
    for (const gform of gforms) {

      /**
       * DOM Elements
       */
      // Regular Fields
      // All Inputs Text, Simple Passwords, Textareas
      const regularFields = gform.querySelectorAll('[type=text], .gfield-text [type=password], textarea');

      // Complex Fields
      // Special Field: Password + Confirm
      const passwordComplexField = gform.querySelectorAll('.ginput_complex [type=password]');

      // All Fields
      const allFields = [
        ...Array.from(regularFields),
        ...Array.from(passwordComplexField)
      ];

      // Checkboxes
      const checkboxes = gform.querySelectorAll('[type=checkbox]');

      /**
       * Trigger Events
       */
      // On Focus Event
      const onFocus = (ev) => {
        const field = ev.target.closest('li');
        if (field.classList.contains('gfield-password')) {
          ev.target.parentNode.classList.add('is-focused','has-label');
        } else {
          field.classList.add('is-focused','has-label');
        }
      };

      // On Blur Event
      const onBlur = (ev) => {
        const field = ev.target.closest("li");
        if (field.classList.contains('gfield-password')) {
          if (ev.target.value === '') {
            ev.target.parentNode.classList.remove('has-label');
          }
          ev.target.parentNode.classList.remove('is-focused');
        } else {
          if (ev.target.value === '') {
            field.classList.remove('has-label');
          }
          field.classList.remove('is-focused');
        }
      };

      // On Change Event
      const onChange = (ev) => {
        if (ev.target.value !== '') {
          ev.target.closest(".gfield").classList.add('has-label');
        }
      };

      // On Click Checkbox Event
      const onClickCheckbox = (ev) => {
        if ( ev.target.checked ) {
          ev.target.closest('li').classList.add('is-checked');
        } else {
          ev.target.closest('li').classList.remove('is-checked');
        }
      };

      // On Page Load
      const onLoad = (el) => {
        if (el.value === '') return
        const field = el.closest('li');
        if (field.classList.contains('gfield-password')) {
          el.parentNode.classList.add('has-label');
        } else {
          field.classList.add('has-label');
        }
      };

      // Autofill Detection Hack
      // inspiré de https://github.com/klarna/ui/blob/v4.10.0/Field/index.jsx#L104-L114
      const onAutoFillStart = (el) => {
        const field = el.closest('li');
        if (field.classList.contains('gfield-password')) {
          el.parentNode.classList.add('has-label');
        } else {
          field.classList.add('has-label');
        }
      };

      const onAutoFillCancel = (el) => {
        el.parentNode.classList.remove('has-label');
      };

      const onAnimationStart = ({ target, animationName }) => {
        if (target.value !== '') return;
        switch (animationName) {
        case 'onAutoFillStart':
          return onAutoFillStart(target);
        case 'onAutoFillCancel':
          return onAutoFillCancel(target);
        }
      };

      /**
       * Events Listeners
       */
      // Regular fields
      // Note: addEventListener automatically passes the event object into your function as an argument.
      for (const field of regularFields) {
        field.addEventListener('focus', onFocus, false);
        field.addEventListener('blur', onBlur, false);
        field.addEventListener('change', onChange, false);
      }
      // Complex fields : Password
      for (const field of passwordComplexField) {
        field.addEventListener('focus', onFocus, false);
        field.addEventListener('blur', onBlur, false);
        field.addEventListener('change', onChange, false);
      }
      // All Fields, on Load
      for (const field of allFields) {
        onLoad(field);
        field.addEventListener('animationstart', onAnimationStart, false);
      }
      // Checkboxes
      for (const checkbox of checkboxes) {
        checkbox.addEventListener('click', onClickCheckbox, false);
      }


      // Input Radio : Toggle
      // $form.on("click", ".wpcf7-list-item input[type=radio]", function() {
      //   var $self = $(this);
      //   $self.parents(".wpcf7-radio").find(".is-checked").removeClass("is-checked");
      //   if ( $self.is(':checked') ) {
      //     $self.parents(".wpcf7-list-item").addClass('is-checked');
      //   }
      //   // Specific Conditionnal
      //   if( $self.parents(".input__mui").hasClass("has_url") ){
      //     var $site_url = $(".input__mui.content_url");
      //     if($self.val() === "Oui"){
      //       $site_url.show();
      //     } else {
      //       $site_url.hide();
      //     }
      //   }
      // });


      /**
       * Submit Btn disable / enable
       * We cannot use the real disabled attribute because it prevents form submission
       * so we fake it with a class
       */
      const submitBtn = gform.querySelector('.gform_button[type="submit"]');
      // On Load / Init
      submitBtn.classList.remove('is-disabled');
      // On Submit
      submitBtn.addEventListener('click', () => {
        submitBtn.classList.add('is-disabled');
        document.body.classList.add('is-loading');
      });


      /**
       * Textarea auto size
       * We remove textarea rows attribute only because we have Autosize to resize it.
       * We're doing it in JS because there's no other way to do it with Gravity :sweat:
       */
      const textareas = gform.querySelectorAll('textarea');
      for (const el of textareas) {
        el.removeAttribute('rows');
        autosize(el);
      }
    }
  }

});

// Disable checkboxes when field is over a given number of choices.
$( ".gform_wrapper form" ).on( 'click', 'li input[type="checkbox"]', function( event ) {
  if ( $( this ).closest( 'ul' ).attr( 'id' ) == "input_3_25" || $( this ).closest( 'ul' ).attr( 'id' ) == "input_5_25" ) {
    var sub_skill_list = $( this ).closest( 'li' ).attr('data-sub-skill');
    var sub_skills = sub_skill_list.split(",");
    if ( $( this ).is( ':checked' ) ) {
      $( 'li.js-sub-skill-titles' ).removeClass('visually-hidden');
      sub_skills.forEach(function( item ){
        if ( "" != item ) {
          $( 'li[data-sub-skill-id=' + item + ']' ).removeClass('visually-hidden');
        }
      });
    } else {
      if ( 0 == $( '#' + $( this ).closest( 'ul' ).attr( 'id' ) + ' li input[type="checkbox"]:checked' ).length ) {
        $( 'li.js-sub-skill-titles' ).addClass('visually-hidden');
      }
      sub_skills.forEach(function( item ){
        if ( "" != item ) {
          $( 'li[data-sub-skill-id=' + item + ']' ).addClass('visually-hidden');
          $( 'li[data-sub-skill-id=' + item + '] input[type=checkbox]' ).prop( "checked", false );
        }
      });
    }

    disable_checkboxes( $( this ).closest( 'ul' ).attr( 'id' ) );
  }
});

disable_checkboxes( 'input_5_25' );

function disable_checkboxes( parent ) {
  if ( ch_js.provider_form.skill_max_num <= $( '#' + parent + ' li input[type="checkbox"]:checked' ).length ) {
    $( '#' + parent + ' li input[type="checkbox"]:not(:checked)' ).attr('disabled', true);
  } else {
    $( '#' + parent + ' li input[type="checkbox"]:not(:checked)' ).removeAttr( "disabled" );
  }
}