window.addEventListener('load', function() {
  var xhr;
  var loadPostsAjaxUrl;
  var loadAdvisorInfosButtons = document.querySelectorAll('.js-advisor-contact-infos-loader');

  // Reload Posts on submitButton click
  if(loadAdvisorInfosButtons.length){
    for (let i = 0; i < loadAdvisorInfosButtons.length; i++) {
      loadAdvisorInfosButtons[i].addEventListener('click', function(){

        if ( ! loadAdvisorInfosButtons[i].classList.contains( 'is-loaded' ) ) {
          loadAdvisorInfos( loadAdvisorInfosButtons[i] );
        }
      });
    }
  }

  // Show previous Page & Load hidden next Page with filters
  function loadAdvisorInfos( element ){
    var adVisorId = element.dataset.id;

    var card_loading = document.querySelector('.js-advisor-contact-infos-loading-' + adVisorId);

    // Disable filterSubmit & loadAdvisorInfosButton during ajax load
    card_loading.classList.add('visually-hidden');
    element.classList.add('is-loaded');

    var loadAdvisorInfosContainer = document.querySelectorAll('.js-advisor-contact-infos-' + adVisorId );

    // Ajax Request
    xhr = new XMLHttpRequest();
    loadPostsAjaxUrl = ch_js.ajaxurl
    + '?action=load_advisor_contact_infos'
    + '&advisor_id=' + adVisorId;
    xhr.open('GET', loadPostsAjaxUrl);
    xhr.onload = function() {
      if (xhr.status === 200) {

        // Add page results to list
        for (let i = 0; i < loadAdvisorInfosContainer.length; i++) {
          loadAdvisorInfosContainer[i].innerHTML += xhr.responseText;
        }

      } else {
        element.classList.remove('visually-hidden');
      }
    };
    xhr.send();
  }
});
