/**
 * Photoswipe 4
 * easier & modern implementation with constructor & es6
 * Note: History, PID & Hash URL are not implemented in this demo
 * @author sylvain@choosit.com
 */

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default.js';

class PhotoSwipeGallery {

  constructor(el, options) {
    this.el = el;
    this.extraOptions = options;
    this.items = this.el.querySelectorAll('figure');
    this.slides = [];
    this.parseItems();
    this.attachEvents();
    this.options = {
      // Core Default Options
      history: false,
      preload: [1, 1],
      getThumbBoundsFn: (index) => {
        const thumb = this.items[index].querySelector('img');
        const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
        const rect = thumb.getBoundingClientRect();
        return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
      }
    }
  }

  parseItems () {
    for (const [index, item] of this.items.entries()) {
      const size = item.querySelector('a').getAttribute('data-size').split('x');

      this.slides[index] = {
        src: item.querySelector('a').getAttribute('href'),
        msrc: item.querySelector('img').getAttribute('src'),
        w: parseInt(size[0], 10),
        h: parseInt(size[1], 10)
      };
      if (item.querySelector('figcaption')) {
        this.slides[index].title = item.querySelector('figcaption').innerHTML;
      }
    }
  }

  openPhotoSwipe (index) {
    this.options.index = index;
    if (this.extraOptions !== undefined) {
      Object.assign(this.options, this.extraOptions);
    }
    const pswp = document.querySelector('.pswp');
    const gallery = new PhotoSwipe( pswp, PhotoSwipeUI_Default, this.slides, this.options);
    gallery.init();
  }

  attachEvents () {
    for (const [index, item] of this.items.entries()) {
      item.querySelector('a').addEventListener('click', (ev) => {
        ev.preventDefault();
        this.openPhotoSwipe(index);
      })
    }
  }
}

// Init with options
// see https://photoswipe.com/documentation/options.html
const galleries = document.querySelectorAll('.js-photoswipe');

for (const gallery of galleries) {
  new PhotoSwipeGallery(gallery, {
    fullscreenEl: false,
    shareEl: false,
    zoomEl: true,
    tapToToggleControls: false
  });
}
