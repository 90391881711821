/**
 * LazyLoading & Intersection Observer
 */

import lozad from 'lozad'

// Images
export function lazyLoadObserve() {
  const simpleLazyImgs = document.querySelectorAll('.lazy-image img');
  const observer = lozad(simpleLazyImgs, {
    threshold: 0,
    loaded: function(el) {
      el.removeAttribute('data-src');
      el.removeAttribute('data-srcset');
    }
  });

  for (const simpleLazyImg of simpleLazyImgs) {
    if (!simpleLazyImg.getAttribute('data-background-image')) {
      simpleLazyImg.onload = function() {
        this.parentNode.setAttribute('data-fully-loaded', true);
      };
    }
  }
  return observer;
}


// Sections
const sectionsObserver = lozad('[data-animated]', {
  rootMargin: '30px 0px'
});

sectionsObserver.observe();


const defaultObserver = lazyLoadObserve();
defaultObserver.observe();

const revealImgsContainer = document.querySelectorAll('.reveal-lazy-image');
const revealImgs = document.querySelectorAll('.reveal-lazy-image img');

if(revealImgsContainer.length) {
  const revealImgObserver = lozad(revealImgs, {
    threshold: 0.2,
    load: function(el) {
      el.parentNode.classList.add('reveal-me');
    },
    loaded: function(el) {
      el.src = el.getAttribute('data-src');
    }
  });
  revealImgObserver.observe();

  for (const revealImg of revealImgs) {
    if (!revealImg.getAttribute('data-background-image')) {
      revealImg.onload = function() {
        this.setAttribute('data-fully-loaded', true);
      };
    }
  }
}
