/**
 * Fixed Header with headroom.js
 * @dependencies headroom.js
 */

import Headroom from 'headroom.js';

// Header Element
const headerEl = document.querySelector('.js-fixed-header');

// Optional : If Hero is fullscreen we add an offset equals to
// hero's height minus header's heihgt
const hero = document.querySelector('.hero--fullscreen');
let offset = 0;
if (hero) {
  offset = hero.offsetHeight - headerEl.offsetHeight
}

// Headroom Options
const fixedHeader = new Headroom(headerEl, {
  offset : offset,
  classes : {
    // when element is initialised
    initial : "initialized",
    // when scrolling up
    pinned : "is-pinned",
    // when scrolling down
    unpinned : "is-unpinned",
    // when above offset
    top : "is-top",
    // when below offset
    notTop : "is-not-top",
    // when at bottom of scoll area
    bottom : "is-bottom",
    // when not at bottom of scroll area
    notBottom : "is-not-bottom"
  }
});

fixedHeader.init();

if ( window.scrollY > offset ) {
  fixedHeader.unpin();
}