/**
 * Masonry Grid with lazy loading
 * @dependencies isotope-layout
 * @dependencies imagesloaded
 * @dependencies scrollmonitor
 */

import Masonry from 'masonry-layout'

const grids = document.querySelectorAll('.js-masonry');
if (grids.length) {

  for (const grid of grids) {
    // General Layout
    // We init the masonry instance but we dont fire it yet
    const masonry = new Masonry(grid, {
      itemSelector: '.js-grid-item',
      columnWidth: '.js-grid-sizer',
      percentPosition: true,
      resize: true,
      stagger: 30
    });

    const items = grid.querySelectorAll('.js-grid-item');
    for (const item of items) {

      // Some preparation
      const img = item.querySelector('img');
      const ratio = img.dataset.ratio;
      item.querySelector('.masonry-item__thumb').style.paddingBottom = ratio * 100 + '%';
    }
    masonry.layout();
  }
}
