// Import all your components here
// But don't code inside this file.
// camelCase your file name

// Utilities first so you can use it through all your other files
import './utils/polyfills';
import './utils/utils';

// Global files next, to use everywhere. Potentially needing utilities.
// Don't forget all the dependencies needed by your framework.
// Foundation needs jQuery & what-input to work.
import $ from 'jquery';
import 'what-input';
import './global/webfont';

// Foundation 6.5.3
import './global/foundation';
$(document).foundation();

// Components
import './components/fixedHeader';
import './components/gravityForm';
import './components/inViewport';
import './components/lazyLoad';
import './components/loadPosts';
import './components/masonry';
import './components/mobileMenu';
import './components/pageStandard';
import './components/photoswipe';
import './components/searchbar';
import './components/swiper';
import './components/smoothScroll';

import './components/guideForm';
import './components/searchForm';
import './components/homePage';
import './components/singleProvider';

// Enables HMR for JS files imported here
if (module.hot)
  module.hot.accept();

// Signed by Choosit!
console.log(`%cMade with ❤️ by Choosit!`, `color: #009ee0;`);
