import $ from 'jquery';

window.addEventListener('load', function() {

  const guideForm = document.querySelector('#guide_form');

  if( ! guideForm )
    return;

  $( '.js-question' ).click( function() {
    $('.js-providers-container').html( "" );

    $( '.js-question.js-selected' ).removeClass( 'js-selected' );
    $(this).addClass( 'js-selected' );

    $('.js-sub-question-container:not(:has(.js-sub-question-hidden))').addClass( 'js-sub-question-hidden' );
    $('.js-sub-question-container[data-question-key=' + $(this).attr('data-value') + ']').removeClass( 'js-sub-question-hidden' );

    $( 'input[name=main-question]' ).val( $(this).attr('data-slug') );
    $( 'input[name=secondary-question]' ).val('');
    $( 'input[name=provider_skill]' ).val('');
    $( '.js-sub-question.js-selected' ).removeClass( 'js-selected' );

    $( '.js-department-container' ).addClass( 'js-sub-question-hidden' );
    $( '.js-department-container > select' ).val( '' );

    if ( ch_js.guideForm.dpt_required ) {
      $( '.js-guidance-button' ).addClass( 'js-hidden' );
    }

    goToAnchor( '' );
  });

  $( '.js-sub-question' ).click( function() {
    $('.js-providers-container').html( "" );

    $( '.js-sub-question.js-selected' ).removeClass( 'js-selected' );
    $(this).addClass( 'js-selected' );

    $( 'input[name=secondary-question]' ).val( $(this).attr('data-slug') );
    $( 'input[name=provider_skill]' ).val( $(this).attr( 'data-skill' ) );

    $( '.js-department-container' ).removeClass( 'js-sub-question-hidden' );

    goToAnchor( '' );
  });

  $( '.js-department-container > select' ).change( function(){
    $('.js-providers-container').html( "" );
    
    if ( ch_js.guideForm.dpt_required ) {
      if ( $( this ).val() != '' ) {
        $( '.js-guidance-button' ).removeClass( 'js-hidden' );
      } else {
        $( '.js-guidance-button' ).addClass( 'js-hidden' );
      }
    }

    goToAnchor( '' );
  } );

  // $( '#guide_form' ).submit(function(){
  //   $( this ).ajaxSubmit({
  //     'target': '.js-providers-container',
  //     beforeSubmit : function() {
  //       $( '.js-guidance-button' ).addClass( 'js-loading-results');
  //     },
  //     success:    function() {
  //       $( '.js-guidance-button' ).removeClass( 'js-loading-results');
  //     }
  //   });

  //   return false;
  // });

  $( '.js-guidance-button' ).click( function( e ) {
    e.preventDefault();

    $( '.js-guidance-form-required-message' ).addClass( 'js-hidden' );

    if ( $( '.js-guidance-button' ).hasClass( 'disabled' ) ) {
      $( '.js-guidance-form-required-message' ).removeClass( 'js-hidden' );
    } else {
      $('.js-providers-container').html( "" );

      $( '#guide_form' ).submit();
    }

  } );

  if ( '' != $( '.js-providers-container' ).html() ) {
    var providers_container = document.querySelector( '.js-providers-container' );
    var no_result_container = providers_container.querySelector( '.results--noresult' );
    console.log(no_result_container);
    if ( typeof( no_result_container ) != 'undefined' && no_result_container != null ) {
      // Go to destination.
      $('html,body').animate({
        scrollTop: $( document ).height()
      }, 1000, 'swing');
    } else {
      goToAnchor( '.js-providers-container' );
    }
  }

  // $( '.js-providers-container' ).on( 'click', '.providers-search-pagination a', function( e ) {
  //   e.preventDefault();

  //   let searchParams = new URLSearchParams( $(this).attr('href') );
  //   let page = searchParams.get('current_page');

  //   $( '#guide_form input[name=current_page]' ).val( page );
  //   $( '#guide_form' ).submit();
  // } );

});

function goToAnchor( elementID ) {
  // Calculate destination place.
  var dest=$(document).height();

  if ( '' !== elementID ) {
    if($(elementID).offset().top > $(document).height()-$(window).height()){
      dest=$(document).height()-$(window).height();
    }else{
      dest=$(elementID).offset().top;
    }
    var headerHeight = document.querySelector( '.js-fixed-header' ).offsetHeight + 50;
  
    dest -= headerHeight;
  }

  // Go to destination.
  $('html,body').animate({
    scrollTop: dest
  }, 1000, 'swing');
}
