import $ from 'jquery';

window.addEventListener('load', function() {

  const searchForm = document.querySelector('#provider_search_form');

  if( ! searchForm )
    return;

  const searchResults = document.querySelector('.js-providers-search-results-container');
  const searchButton = searchForm.querySelector('button');
  const searchLists = searchForm.querySelectorAll('select');
  const requiredMessage = searchForm.querySelector('.js-message-fields-required');
  const resetButton = searchForm.querySelector('.js-reset-button');

  searchButton.addEventListener( 'click', function( e ){
    const providerSkill = $( '#provider_search_form select[name=provider_skill]' ).val();
    const provider_sub_skill = $( '#provider_search_form select[name=provider_sub_skill]' ).val();
    e.preventDefault();

    if (providerSkill || provider_sub_skill) {
      searchButton.classList.add('js-loading-results');

      requiredMessage.classList.add('visually-hidden');
      var params = '';
      for (var i = 0; i < searchForm.elements.length; i++) {
        searchForm.elements[i].classList.remove('js-input-error');

        if (searchForm.elements[i].value === '' && 'true' == searchForm.elements[i].dataset.required) {
          searchForm.elements[i].classList.add('js-input-error');
          searchButton.classList.remove('js-loading-results');

          requiredMessage.classList.remove('visually-hidden');

          return false;
        } else if (searchForm.elements[i].value !== '') {
          params += '&' + searchForm.elements[i].name + '=' + searchForm.elements[i].value;
        }
      }

      if (searchForm.dataset.type == 'ajax') {
        searchResults.innerHTML = '';

        // Ajax Request
        var xhr = new XMLHttpRequest();
        var loadPostsAjaxUrl = ch_js.ajaxurl;
        xhr.open('POST', loadPostsAjaxUrl, true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
          if (xhr.status === 200) {
            // Add page results to list
            searchResults.innerHTML += xhr.responseText;
            searchButton.classList.remove('js-loading-results');
          }
        };
        xhr.send(params);
      } else {
        searchForm.submit();
      }
    } else {
      requiredMessage.classList.remove('visually-hidden');
    }
  });

  // Changement de la classe sur les liste déroulante
  for(var i=0; i < searchLists.length; i++){
    searchLists[i].addEventListener('change', function(){
      if ( this.value == '' || this.value == 'occitanie' ) {
        if ( this.value != 'occitanie' ) {
          this.classList.add( 'js-select--is-empty' ); 
        } else if ( this.classList.contains( 'js-select--is-empty' ) ) {
          this.classList.remove( 'js-select--is-empty' );
        }
        var hide = true;
        for(var j=0; j < searchLists.length; j++){
          if ( searchLists[j].value != '' && searchLists[j].value != 'occitanie' ) {
            hide = false;
          }
        }
        if ( hide ) {
          resetButton.classList.add( 'visually-hidden' );
        }
      } else {
        this.classList.remove( 'js-select--is-empty' );
        resetButton.classList.remove( 'visually-hidden' );
      }
    });
  }

  $( '.js-providers-search-results-container' ).on( 'click', '.providers-search-pagination a', function( e ) {

    if ( searchForm.dataset.type == 'ajax' ) {
      e.preventDefault();

      let searchParams = new URLSearchParams( $(this).attr('href') );
      let page = searchParams.get('current_page');

      $( '#provider_search_form input[name=current_page]' ).val( page );
      searchButton.click();
    }
  } );

});

function goBack() {
  window.history.back();
}