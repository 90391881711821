/**
 * Smooth Scroll when click on link
 * @dependencies jump.js
 * @dependebcues ez.js
 */

import Jump from 'jump.js'
import { easeInOutCubic } from 'ez.js';

const links = document.querySelectorAll('[data-jump]');
links.forEach((el) => {

  const url = el.getAttribute('href');
  const hash = url.substring(url.indexOf('#') + 1);
  const duration = el.getAttribute('data-jump-duration') ? el.getAttribute('data-jump-duration') : 1200;

  el.addEventListener('click', (ev) => {
    ev.preventDefault();
    Jump(`#${hash}`, {
      duration: duration,
      easing: easeInOutCubic
    });
  });

});
