/**
 * Page Standard Scripts
 * - Animate Numbers
 * - Parallaxe Image Block
 */
import lozad from 'lozad'
import * as basicScroll from 'basicscroll'

// Block Digits count animation
const numbers = document.querySelectorAll('.js-animate-digit');
if (numbers.length) {

  for (const number of numbers) {

    // Field Value
    const value = parseFloat(number.innerHTML);

    // Extract Numbers & convert to int
    // console.log(value);
    // @TODO Animation

  }

}

// Parallax
const parallaxBlocks = document.querySelectorAll('.js-parallax');
if (parallaxBlocks.length) {

  // CustomObserver
  const customObserver = lozad('.lozad-parallax', {
    threshold: 0.2,
    loaded: (img) => {

      // Basic Scroll
      const parallax = basicScroll.create({
        elem: img,
        from: 'top-bottom',
        to: 'bottom-top',
        direct: true,
        props: {
          '--translateY': {
            from: '-200px',
            to: '0'
          }
        }
      })
      parallax.start()

    }
  });
  customObserver.observe();

}
